import { inject, Injectable } from '@angular/core';
import {
	HttpClient,
	HttpEventType,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { chain } from 'lodash';
import { Observable, of, Subscriber, timer } from 'rxjs';
import { catchError, debounce, filter, map, tap } from 'rxjs/operators';

import {
	DefaultValues,
	GeneralSettingDTO,
	HolidayDTO,
	ImagingCenter,
	LeaveActorDTO,
	ReferringPhysicianDTO,
	TableConfig,
	TableView,
	TemplateModelDTO,
	UploadInstance,
	ViewerDTO,
	CalendarSettingDTO,
	AetFullDTO,
	ModalityDTO,
	ProcedureCodeDTO,
	UserAccountDTO, PortalShareConfigDTO,
} from '../model';
import {
	DOCUMENT_URL,
	RESOURCE_URL,
	SETTING_URL,
	SHARED_URL,
	STATIC_URL,
} from '../urls';
import { downloadFile } from '../utils';
import { SortDirection } from '@angular/material/sort';

type ListType = 'STD' | 'NORMAL';

@Injectable()
export class SettingService {
	private _http = inject(HttpClient);

	changeUILanguage(lang: string): Observable<any> {
		return this._http.get(`${SETTING_URL}/change-ui-lang`, {
			params: { lang },
		});
	}

	saveUnavailability(holiday: HolidayDTO): Observable<any> {
		if (holiday.id)
			return this._http.put(
				`${SETTING_URL}/${holiday.type === 'HOLIDAY' ? 'holidays' : 'leaves'}`,
				holiday
			);
		return this._http.post(
			`${SETTING_URL}/${holiday.type === 'HOLIDAY' ? 'holidays' : 'leaves'}`,
			holiday
		);
	}

	saveModality(modality: ModalityDTO): Observable<any> {
		return this._http.post(RESOURCE_URL + '/modalities', modality);
	}

	saveProcedureCode(
		procedureCode: ProcedureCodeDTO
	): Observable<ProcedureCodeDTO> {
		return this._http.post<ProcedureCodeDTO>(
			`${SHARED_URL}/procedure-codes`,
			procedureCode
		);
	}

	saveBillingCode(code: any): Observable<any> {
		return this._http.post(RESOURCE_URL + '/billing-codes', code);
	}

	saveAet(aet: AetFullDTO): Observable<any> {
		return this._http.post(`${SETTING_URL}/aets`, aet);
	}

	createImagingCenter(center: ImagingCenter): Observable<any> {
		return this._http.post(SETTING_URL + '/imaging-center', center);
	}

	updateImagingCenter(center: ImagingCenter): Observable<any> {
		return this._http.put(SETTING_URL + '/imaging-center', center);
	}

	deleteUser(user: any): Observable<any> {
		return this._http.get(SETTING_URL + `/deleteUser`, {
			params: { id: String(user.id) },
		});
	}

	saveUser(user: UserAccountDTO): Observable<UserAccountDTO> {
		return this._http.post<UserAccountDTO>(`${SETTING_URL}/users`, user);
	}

	updateUser(user: any): Observable<any> {
		return this._http.post(SETTING_URL + '/updateUser', user);
	}

	deleteProfile(id: any): Observable<any> {
		return this._http.delete(`${SETTING_URL}/profiles/${id}`);
	}

	saveProfile(profile: any): Observable<any> {
		return this._http.post(`${SETTING_URL}/profiles`, profile);
	}

	getCalendarSetting(id: number): Observable<CalendarSettingDTO> {
		return this._http.get<CalendarSettingDTO>(
			`${SETTING_URL}/calendar-setting/${id}`
		);
	}

	saveCalendarSetting(
		calSetting: CalendarSettingDTO
	): Observable<CalendarSettingDTO> {
		return this._http.post<CalendarSettingDTO>(
			`${SETTING_URL}/calendar-setting`,
			calSetting
		);
	}

	deleteAet(aetId: number): Observable<any> {
		return this._http.delete(`${SETTING_URL}/aets/${aetId}`);
	}

	deleteProcedureCode(id: number): Observable<any> {
		return this._http.delete(`${SHARED_URL}/procedure-codes/${id}`);
	}

	deleteUnavailability(
		id: number,
		unavailabilityType: 'HOLIDAY' | 'LEAVE'
	): Observable<any> {
		return this._http.delete(
			`${SETTING_URL}/${unavailabilityType === 'HOLIDAY' ? 'holidays' : 'leaves'}/${id}`
		);
	}

	deleteBillingCode(id: number): Observable<any> {
		return this._http.delete(RESOURCE_URL + `/billing-codes`, {
			params: { id: String(id) },
		});
	}

	isUserExists(username: string): Observable<boolean> {
		return this._http.get<boolean>(RESOURCE_URL + `/isExists/${username}`);
	}

	getTemplateModels(
		listType: string = 'STD'
	): Observable<TemplateModelDTO[]> {
		return this._http.get<TemplateModelDTO[]>(
			`${SETTING_URL}/template-models`,
			{ params: { listType } }
		);
	}

	saveTemplateModel(
		templateModel: TemplateModelDTO
	): Observable<TemplateModelDTO> {
		return this._http.post<TemplateModelDTO>(
			`${SETTING_URL}/template-models`,
			templateModel
		);
	}


	duplicateTemplateModel(
		templateModel: TemplateModelDTO
	): Observable<TemplateModelDTO> {
		return this._http.post<TemplateModelDTO>(
			`${SETTING_URL}/template-models/duplicate`,
			templateModel
		);
	}

	createTemplateModelFromReport(
		tm: TemplateModelDTO,
		reportId: any
	): Observable<TemplateModelDTO> {
		return this._http.post<TemplateModelDTO>(
			`${RESOURCE_URL}/createTemplateFromReport`,
			tm,
			{ params: { reportId } }
		);
	}

	deleteReasonForExam(id: number): Observable<any> {
		return this._http.delete(`${STATIC_URL}/reason-for-exams/${id}`);
	}

	saveSPSStatus(spsStatus: any): Observable<any> {
		return this._http.post(RESOURCE_URL + '/saveSPSStatus', spsStatus);
	}

	deleteTemplateModel(tm: TemplateModelDTO): Observable<any> {
		return this._http.delete(`${SETTING_URL}/template-models/${tm.id}`);
	}

	saveGeneralSetting(generalSetting: GeneralSettingDTO): Observable<boolean> {
		return this._http.post<boolean>(
			`${SETTING_URL}/general-setting`,
			generalSetting
		);
	}

	getHolidays(
		pageSize: number,
		pageIndex: number,
		query: string
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			query,
		};

		return this._http.get(`${SETTING_URL}/holidays`, { params: params });
	}

	getLeaves(
		pageSize: number,
		pageIndex: number,
		query: string
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			query,
		};

		return this._http.get(`${SETTING_URL}/leaves`, { params: params });
	}

	createTableView(view: TableView): Observable<TableView> {
		return this._http.post<TableView>(
			`${SETTING_URL}/createTableView`,
			view
		);
	}

	getTableViews(id: any, module: string): Observable<TableView[]> {
		return this._http.get<TableView[]>(`${SETTING_URL}/getTableViews`, {
			params: { id, module },
		});
	}

	deleteTableView(id: number): Observable<boolean> {
		return this._http.get<boolean>(`${SETTING_URL}/deleteTableView`, {
			params: { id: String(id) },
		});
	}

	getViewers(): Observable<ViewerDTO[]> {
		return this._http.get<ViewerDTO[]>(`${SHARED_URL}/viewers`);
	}

	createViewer(viewer: ViewerDTO): Observable<ViewerDTO> {
		return this._http.post<ViewerDTO>(`${SHARED_URL}/viewers`, viewer);
	}

	deleteViewer(viewer: ViewerDTO): Observable<boolean> {
		return this._http.delete<boolean>(`${SHARED_URL}/viewers/${viewer.id}`);
	}

	getNormalTemplateModels(): Observable<TemplateModelDTO[]> {
		return this.getTemplateModelsData('NORMAL');
	}

	getStandardTemplateModels(): Observable<TemplateModelDTO[]> {
		return this.getTemplateModelsData('STD');
	}

	getTemplateModelsData(listType: ListType): Observable<TemplateModelDTO[]> {
		const params = { listType };
		return this._http.get<TemplateModelDTO[]>(
			`${SETTING_URL}/template-models`,
			{ params }
		);
	}

	queryTemplateModels(
		pageSize: number,
		pageIndex: number,
		active: string,
		direction: SortDirection,
		value: string
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: active,
			direction,
			value,
		};
		return this._http.get(`${SETTING_URL}/paged-template-models`, {
			params,
		});
	}

	getPrintingModels(): Observable<any> {
		return this._http.get<any>(`${SETTING_URL}/printingModels`);
	}

	getViewer(name: string): Observable<ViewerDTO> {
		const params = { name };
		return this._http.get<ViewerDTO>(`${SHARED_URL}/viewer`, { params });
	}

	getTableConfig(name: string, userId: any): Observable<any> {
		return this._http.get(`${SETTING_URL}/grid-config`, {
			params: { name, userId },
		});
	}

	saveTableConfig(tableConfig: TableConfig): Observable<TableConfig> {
		return this._http.post<TableConfig>(
			`${SETTING_URL}/grid-config`,
			tableConfig
		);
	}

	public uploadFiles(files: UploadInstance[]): Observable<UploadInstance> {
		const observables = chain(files)
			.map(
				(file: UploadInstance) =>
					new Object({ file, subject: this._uploadFileItem(file) })
			)
			.value();

		return new Observable(subject =>
			this._uploadChunk(0, observables, subject)
		);
	}

	private _uploadChunk(
		index: number,
		chunks: string | any[],
		subject: Subscriber<UploadInstance>
	) {
		if (index === chunks.length) {
			subject.complete();
		} else {
			subject.next(chunks[index].file);
			chunks[index].subject.subscribe(
				(data: any) => subject.next(data),
				(err: any) => subject.error(err),
				() => {
					this._uploadChunk((index += 1), chunks, subject);
				}
			);
		}
	}

	createTemplates(): Observable<any> {
		return this._http.post(`${DOCUMENT_URL}/create-templates`, null);
	}

	uploadProceduresFile(file: File) {
		const instance = new UploadInstance(file, 0, file.name);
		return this._uploadFileItem(instance);
	}

	private _uploadFileItem(item: UploadInstance) {
		const data: FormData = new FormData();
		data.append('file', item.file, item.fullPath);

		const config = new HttpRequest(
			'POST',
			`${DOCUMENT_URL}/files-upload`,
			data,
			{
				reportProgress: true,
			}
		);

		return this._http.request(config).pipe(
			tap(event => {
				if (event.type === HttpEventType.UploadProgress) {
					item.progress = (event.loaded / event.total) * 100;
				}
			}),
			filter(event => event.type === HttpEventType.Response),
			map((response: HttpResponse<any>) => response.body),
			debounce(() => timer(50)),
			catchError(() => of(null))
		);
	}

	updateTemplateCategory(
		category: string,
		updatedCategory: any
	): Observable<any> {
		return this._http.put(
			`${SETTING_URL}/template-models/update-category/`,
			updatedCategory,
			{ params: { category } }
		);
	}

	deleteTemplateByCategory(category: string): Observable<any> {
		return this._http.delete(
			`${SETTING_URL}/template-models/delete-by-category`,
			{ params: { category } }
		);
	}

	deleteAllProcedureCodes(): Observable<any> {
		return this._http.delete(`${SHARED_URL}/procedure-codes-purge`);
	}

	saveDefaultValues(defaultValues: DefaultValues): Observable<DefaultValues> {
		return this._http.post<DefaultValues>(
			`${SETTING_URL}/default-values`,
			defaultValues
		);
	}

	getDefaultValues(): Observable<DefaultValues> {
		return this._http.get<DefaultValues>(`${SETTING_URL}/default-values`);
	}

	getTemplateModelCategories(): Observable<any> {
		return this._http.get(`${SETTING_URL}/template-model-categories`);
	}

	downloadAllTemplates(): Observable<Blob> {
		return this._http
			.get(`${SETTING_URL}/template-models/download`, {
				responseType: 'blob',
			})
			.pipe(tap(it => downloadFile(it, 'zip')));
	}

	getHl7Servers(): Observable<any> {
		return this._http.get(`${SETTING_URL}/hl7-servers`);
	}

	getDicomServers(): Observable<any> {
		return this._http.get(`${SETTING_URL}/dicom-servers`);
	}

	getCustomServers(): Observable<any> {
		return this._http.get(`${SETTING_URL}/custom-servers`);
	}

	createDicomServer(data: any): Observable<any> {
		return this._http.post(`${SETTING_URL}/dicom-servers`, data);
	}

	createHl7Server(data: any): Observable<any> {
		return this._http.post(`${SETTING_URL}/hl7-servers`, data);
	}

	createCustomServer(data: any): Observable<any> {
		return this._http.post(`${SETTING_URL}/custom-servers`, data);
	}

	deleteDicomServer(id: number): Observable<any> {
		return this._http.delete(`${SETTING_URL}/dicom-servers/${id}`);
	}

	deleteHl7Server(id: number): Observable<any> {
		return this._http.delete(`${SETTING_URL}/hl7-servers/${id}`);
	}

	deleteCustomServer(id: number): Observable<any> {
		return this._http.delete(`${SETTING_URL}/custom-servers/${id}`);
	}

	saveReferringPhysician(
		data: ReferringPhysicianDTO
	): Observable<ReferringPhysicianDTO> {
		return this._http.post<ReferringPhysicianDTO>(
			`${SHARED_URL}/referring-physicians/save`,
			data
		);
	}

	getLeavesActors(): Observable<LeaveActorDTO[]> {
		return this._http.get<LeaveActorDTO[]>(`${SETTING_URL}/leaves/actors`);
	}

	deleteImagingCenter(id): Observable<any> {
		return this._http.delete(`${SETTING_URL}/imaging-center/${id}`);
	}

	duplicateCenter = (id: any): Observable<ImagingCenter> => {
		return this._http.get(`${SETTING_URL}/imaging-center/duplicate/${id}`);
	};

	getGeneralSetting(centerId: number): Observable<GeneralSettingDTO> {
		return this._http.get<GeneralSettingDTO>(
			`${SETTING_URL}/general-setting/${centerId}`
		);
	}

	getAets = (
		pageSize: number,
		pageIndex: number,
		active: string,
		direction: SortDirection
	) => {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: `${active},${direction}`,
		};
		return this._http.get(`${SETTING_URL}/aets`, { params });
	};

	getAet(aetId: any): Observable<AetFullDTO> {
		return this._http.get<AetFullDTO>(`${SETTING_URL}/aets/${aetId}`);
	}

	getPortalConfig(): Observable<PortalShareConfigDTO> {
		return this._http.get<PortalShareConfigDTO>(`${SETTING_URL}/portal-config`);
	}

	savePortalConfig(portalConfig: PortalShareConfigDTO): Observable<PortalShareConfigDTO> {
		return this._http.post(`${SETTING_URL}/portal-config`, portalConfig);
	}
}
